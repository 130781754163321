<template>
    <div>
        <v-form class="pa-4" ref="form" v-model="formValid" lazy-validation @submit.prevent="getHzResultData()">
        <div class="d-flex align-center">
          <div class="px-2 fsize14">
            <div class="pb-1">Key</div>
            <v-text-field outlined dense v-model="currentkey"></v-text-field>
          </div>

          <div class="px-2 fsize14">
            <div class="pb-1">Name</div>
            <v-autocomplete outlined dense :items="getHzkeys" :menu-props="{ bottom: true, offsetY: true }" :rules="currentNameRules" v-model="currentName"></v-autocomplete>
          </div>
          
          <div class="px-2 d-flex justify-end">
            <v-btn height="40" type="submit" color="primary" depressed class="text-capitalize fsize14">Submit</v-btn>
          </div>
          <div class="loaderPart d-flex align-center">
            <v-progress-circular class="ml-2" indeterminate v-if="getLoader" size="18" :width="2" color="black"></v-progress-circular>
          </div>
        </div>
      </v-form>

      <div class="px-4">
        <span class="secondaryColor">Count:</span> <span class="primaryColor">{{ getHzCount }}</span>
      </div>
      <div class="pa-4" v-if="getHzResult != '' ">
        <json-viewer :value="getHzResult"></json-viewer>
      </div>
      <div class="pa-4"  v-else>
        No Records Found
      </div>
        <!-- <v-simple-table style="width: fit-content;">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Keys</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(key,id) in getHzkeys" :key="id">
                        <td>
                           {{ id + 1 }}
                        </td>
                        <td>
                           {{ key }}
                        </td>
                        
                    </tr>
                </tbody>
        </v-simple-table> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);

export default {
    data() {
        return {
            currentkey: '',
            currentName: '',
            formValid: false,
            currentkeyRules: [(v) => !!v || "Key is required"],
            currentNameRules: [(v) => !!v || "Name is required"],
        }
    },
    computed: {
        ...mapGetters('hazelcast', ['getHzkeys', 'getHzResult', 'getHzCount','getLoader'])
    },
    methods: {
        getHzResultData() {
            if(this.$refs.form.validate()) {
                this.$store.dispatch('hazelcast/getHZDataByKey', { name: this.currentName, key: this.currentkey })
                this.$store.dispatch('hazelcast/getHZcountByName', this.currentName)
            }
        }
    },
    created() {
        this.$store.commit('hazelcast/RESET_STATE')
        this.$store.dispatch('hazelcast/getHazelcastKeys')
    },
}
</script>

<style>
.bg-gray-100 {
    background-color: #ededed;
}
</style>